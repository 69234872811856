
















import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import FormField from '@/shared/components/form/FormField.vue'
import Field from '@/shared/classes/form/field'

@Component({
  components: { FormField }
})
export default class ObjectField extends AbstractField {
  getChild(child: Field): Field {
    if (this.field.dense) {
      child.setDense(true)
    }

    return child
  }
}
